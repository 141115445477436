<template>
  <div class="h-full overflow-hidden" v-loading="loading">
    <div style="padding:20px 0px; background-color: white; border-radius: 10px;">
      <!--div class="flex justify-end w-full items-center p-1 laptop:pb-2 desktop:pb-8">
        <el-input :placeholder="$t('btn.search')" style="width: 275px" size="mini"
          @keyup.enter.native="getDetail" v-model="searchValue">
          <span slot="suffix" @click="getDetail"><svg-icon iconClass="search" /></span>
        </el-input>
      </div-->
      <div>
        <el-table
          :header-cell-style="{height: '55px',background: '#fff','border-bottom': '2px solid #D6D9E1'}"
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column type="index" :label="$t('table.no')" align="left" />
          <el-table-column prop="equipmentName" :label="$t('table.equipment')" align="left" />
          <el-table-column prop="reportIssue" :label="$t('table.reportIssue')" align="left" />
          <el-table-column prop="reportedTime" :label="$t('table.reportedOn')" align="left" />
          <el-table-column prop="reportedByName" :label="$t('table.reportedBy')" align="left" />
          <el-table-column :label="$t('common.status')" align="left">
            <tempate slot-scope="scope">
              {{  scope.row.status == 1 ? 'Resolved' : 'Pending' }}
            </tempate>
          </el-table-column>
        </el-table>
        <div class="flex justify-end">
          <pagination
            v-show="total > 0"
            style="background: #f2f7fa"
            :total="total"
            :page.sync="params.pageNum"
            :limit.sync="params.pageSize"
            @pagination="handlePagechange"
          ></pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getEquipmentReports } from "@/services/form"
export default {
  name: "EquipmentReport",
  data() {
    return {
      tableData: [],
      getList: [],
      loading: false,
      searchValue: '',
      total: 0,
      params: {
        pageNum: 1,
        pageSize: 10,
      }
    }
  },
  async created() {
    await this.getDetail()
  },
  methods: {
    async getDetail() {
      this.loading = true
      await getEquipmentReports( this.params ).then(res => {
        this.tableData = res.data.table.rows
        this.total = res.data.table.total
      }).finally(() => {
        this.loading = false
      })
    },
    async handlePagechange(object) {
      this.params.pageNum = object.page
      this.params.pageSize = object.limit
      await this.getDetail()
    },
  }
}
</script>
<style scoped lang="scss">
::v-deep .el-form-item {
  margin-bottom: 10px;
}
::v-deep .el-dialog__headerbtn .el-dialog__close {
  font-size: 18px;
  font-weight: bold;
}
::v-deep .el-dialog__title {
  color: #82889c;
  font-size: 16px;
}
::v-deep .el-form--label-top .el-form-item__label {
  padding-bottom: 0;
}
::v-deep .el-table th.el-table__cell {
  font-size: 12px;
  font-weight: bold;
}
::v-deep .el-table td.el-table__cell div {
  font-weight: 400;
  font-size: 11px;

  color: #2e3139;
}
::v-deep .el-input__suffix-inner {
  padding-right: 5px;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  svg {
    width: 13px;
    height: 13px;
    cursor: pointer;
  }
}
</style>